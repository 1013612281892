<template lang="">
    <div>
        <CustomerForm />
    </div>
</template>
<script>
export default {
    components:{
        CustomerForm: () => import('@/components/customers/addCustomer/Customer'),

    }
}
</script>
<style lang="">
    
</style>